



































































































































































































































































.formBOX {
  /deep/ .el-form-item__content {
    width: 50%;

    .el-textarea__inner {
      resize: none;
      padding-bottom: 40px;
    }
  }

  /deep/ .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      width: 10rem;
    }
  }

  .el-select {
    width: 100% !important;
  }

  .dffi {
    .el-form-item__content {
      >div {
        width: 148px;
        vertical-align: top;
        display: inline-block;
      }
    }
  }

  .el-upload-list__item {
    vertical-align: top;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
    transition: all .5s cubic-bezier(.55, 0, .1, 1);
    font-size: 14px;
    color: #606266;
    line-height: 1.8;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .el-upload-list__item-actions:hover {
    opacity: 1;
  }

  .el-upload-list__item-actions span {
    display: none;
    display: inline-block;
    cursor: pointer;
  }
}
